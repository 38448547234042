import React, {Component, Fragment, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Link from 'next/link';
import Image from "next/legacy/image";
import Router, {withRouter} from 'next/router';
import {withTranslation} from 'next-i18next';

// styles
import "../../../node_modules/slick-carousel/slick/slick.css";
import styles from "./Bidslist.module.scss";
// icons
import {CircleIcon, CopyBidsIocn, SelectedIcon} from '../../icons'

// ethereum config
import {s3Link} from '../../../config/ethereum-config';

import {apiHelperBlockchain} from '../../../helper/apiHelper';
//import loader
import {ShimmerSimpleGallery} from "react-shimmer-effects";

//Countdown
import Countdown from 'react-countdown';
import dynamic from 'next/dynamic';

// Moralis
import {useMoralis} from "react-moralis";

// images
import avatar from'../../../public/avatar-creator.jpg'
import cardPreview from '../../../public/img/content/video-preview.jpg'
import verified from '../../../public/img/verify.svg'
import likeBidd from '../../../public/img/likenft-d.svg'
import likeBidh from '../../../public/img/likenft-h.svg'

// metadata icon
import Act4 from '../../../public/img/refresh_metadata_icon.png'

const MoreOptIcon = dynamic(() => import('../../icons/moreOptIcon'))
const TimerIcon = dynamic(() => import('../../icons/timerIcon'))
const SignInWithWallet = dynamic(() => import('../../common/signInWithWallet'))

//MoralisInstance
const MoralisInstance = ({ moralisInstance }) => {
    const { Moralis } = useMoralis();
    useEffect(() => {
        moralisInstance(Moralis);
    }, []);
    return <Fragment />
}
class BidsList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Moralis
            callbackInstance: null,

            active: false,
            moreOptsHandle: false,
            imageIPFS: "",
            pimageIPFS: "",
            title: "",
            price: 0,
            currLocale: process.env.defaultLanguage,
            shareModal: false,
            purchaseModal: true,
            wAddress: "",
            owner: "",
            collectionId: "",
            nftId: "",
            auctionType: "",
            is_lazy_mint: false,
            id: "",
            royalty: 0,
            jsonIpfs: "",
            signature: "",
            tokenQty: "",
            copies: 0,
            ownedCopies: 0,
            is_multiple: false,
            is_metadata: "",
            ownerList: [],
            nftUsdPrice: 0,
            imageUrl: "",

            // NFT price
            nftPrice: "",
            discountedPrice: "",

            // for Auction
            closingTime: "",
            highestBidder: "",
            currentBid: 0,

            // Like-Unlike
            favoriteNFT: {
                isLiked: false,
                count: 0
            },

            // for like
            onlike: false,
            is_user_id: false,

            // Image Error
            fallbacksrc: `https://img.freepik.com/free-photo/blue-gradient-abstract-background-empty-room-with-space-your-text-picture_1258-52628.jpg?w=900&t=st=1663052617~exp=1663053217~hmac=8b9423e3947a2eb7f121d61fc3db1b55c90b898389c1c67502abdf82116c1b24`,
            error: false,

            //refresh metadata after loader
            nftLoader: false,
            chainType: "ethereum",
            chainIndex: 1,

            signInWithWallet : false
        }
    }

    handleClick = () => {
        this.setState({ moreOptsHandle: !this.state.moreOptsHandle });
    }

    componentDidMount = async () => {

        const router = Router && Router.router;
        // let chainType = localStorage.getItem('chainType');

        let curBid = "";
        if (this.props.currentBid) {
            let cb = this.props.currentBid;
            curBid = cb.toString();
        }
        this.setState({
            wAddress: this.props.walletAddress,
            owner: this.props.owner,
            pimageIPFS: this.props.imageIPFS,
            title: this.props.title,
            price: this.props.price,
            nftId: this.props.tokenId,
            collectionId: this.props.collection_id,
            auctionType: this.props.auctionType,
            closingTime: this.props.closingTime,
            highestBidder: this.props.highestBidder,
            is_lazy_mint: this.props.is_lazy_mint,
            id: this.props.id,
            royalty: this.props.royalty,
            jsonIpfs: this.props.jsonIpfs,
            signature: this.props.signature,
            currentBid: curBid,
            currLocale: router && router.locale ? router.locale : process.env.defaultLanguage,
            tokenQty: this.props.tokenQty,
            copies: this.props.copies,
            ownedCopies: this.props.ownedCopies,
            is_multiple: this.props.is_multiple,
            is_metadata: this.props.is_metadata,
            ownerList: this.props.ownerList,
            nftUsdPrice: this.props.nftUsdPrice,
            favoriteNFT: this.props.favoriteNFT,

            imageUrl: this.props.imgURL,

            chainIndex: this.props.chainIndex,
            nftPrice: this.props.price,
            discountedPrice: this.props.discounted_price,

        });

        if (localStorage.getItem('user_id') && localStorage.getItem('user_id') !== "" && localStorage.getItem('userData') && localStorage.getItem('walletAddress')) {
            this.setState({ is_user_id: true });
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props !== prevProps) {

            const router = Router && Router.router;
            let chainType = localStorage.getItem('chainType');

            let curBid = "";
            if (this.props.currentBid) {
                let cb = this.props.currentBid;
                curBid = cb.toString();
            }

            this.setState({
                wAddress: this.props.walletAddress,
                owner: this.props.owner,
                pimageIPFS: this.props.imageIPFS,
                title: this.props.title,
                price: this.props.price,
                nftId: this.props.tokenId,
                collectionId: this.props.collection_id,
                auctionType: this.props.auctionType,
                closingTime: this.props.closingTime,
                highestBidder: this.props.highestBidder,
                is_lazy_mint: this.props.is_lazy_mint,
                id: this.props.id,
                royalty: this.props.royalty,
                currentBid: curBid,
                jsonIpfs: this.props.jsonIpfs,
                signature: this.props.signature,
                currLocale: router && router.locale ? router.locale : process.env.defaultLanguage,
                tokenQty: this.props.tokenQty,
                copies: this.props.copies,
                ownedCopies: this.props.ownedCopies,
                is_multiple: this.props.is_multiple,
                is_metadata: this.props.is_metadata,
                ownerList: this.props.ownerList,
                nftUsdPrice: this.props.nftUsdPrice,
                favoriteNFT: this.props.favoriteNFT,

                imageUrl: this.props.imgURL,

                chainIndex: this.props.chainIndex,
                nftPrice: this.props.price,
                discountedPrice: this.props.discounted_price

            });

            if (localStorage.getItem('user_id') && localStorage.getItem('user_id') !== "") {
                this.setState({ is_user_id: true });
            }
        }
    }

    shareCloseModal = () => {
        this.setState({
            shareModal: false,
        })
    }

    buyNow = (e) => {
        e.preventDefault();
        let nD = {};
        nD.itemName = this.state.title;
        nD.price = this.state.price;
        nD.walletAddress = this.state.wAddress;
        nD.collection_id = this.state.collectionId;
        nD.nft_id = this.state.nftId;
        nD.owner = this.state.owner;
        nD.is_lazy_mint = this.state.is_lazy_mint;
        nD.is_multiple = this.state.is_multiple;
        nD.is_metadata = this.state.is_metadata;
        nD.royalty = this.state.royalty;
        nD._id = this.state.id;
        nD.jsonIpfs = this.state.jsonIpfs;
        nD.signature = this.state.signature;
        this.setState({ purchaseModal: true, nftDetails: nD });
    }

    openIPFSImage = async () => {

        // if (this.props.imageIPFS.includes('ipfs')) {
        //     if (this.props.imageIPFS.includes("ipfs://")) {
        //         window.open(`https://ipfs.io/ipfs/${imgUrl.split("ipfs://")[1]}`, "_blank")
        //     }
        //     window.open(this.props.imageIPFS, "_blank");
        // }
        // window.open("https://ipfs.io/ipfs/" + this.props.imageIPFS, "_blank");

        if (this.props.imageIPFS) {
            const regex = new RegExp(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/)
            let ipfsLink = this.props.imageIPFS
            if (this.props.imageIPFS.includes("ipfs://") && !this.props.imageIPFS.includes("ipfs/")) {
                ipfsLink = this.props.imageIPFS.split("ipfs://")[1]
                window.open(
                    "https://upyo.mypinata.cloud/ipfs/" + ipfsLink,  //"https://ipfs.io/ipfs/" + ipfsLink,
                    "_blank"
                );
            }
            else if (this.props.imageIPFS.includes("ipfs/")) {
                ipfsLink = this.props.imageIPFS.split("ipfs/")[1]
                window.open(
                    "https://upyo.mypinata.cloud/ipfs/" + ipfsLink,
                    "_blank"
                );
            }
            else if (regex.test(this.props.imageIPFS)) {
                window.open(this.props.imageIPFS, "_blank")
            }
            else {
                window.open(
                    "https://upyo.mypinata.cloud/ipfs/" + ipfsLink,
                    "_blank"
                );
            }
        }
    }

    getCardImage = (imgUrl) => {
        return `https://upyo.mypinata.cloud/ipfs/${imgUrl.split("ipfs://")[1]}`
    }

    changeForBuyModal = (status) => {
        this.setState({ purchaseModal: status })
    }

    moreOptDefault = (e) => {
        e.preventDefault();
    }

    closeSignInWithWallet = () =>{
        this.setState({signInWithWallet : false})
    }

    likeNft = async (_id, collectionID) => {
        let userID = localStorage.getItem('user_id');
        let wData = { nftID: _id, collectionID: collectionID, userID: userID };
        const accessToken = localStorage.getItem("accessToken");
        let authentication = null;
        if (accessToken) {
            authentication = { "authorization": `Bearer ${accessToken}` }
        }

        if(!accessToken){
        this.setState({signInWithWallet : true})
        return
        }

        await apiHelperBlockchain('addFavorite', 'POST', wData, authentication).then(res => {
            if (res.data.status === true) {
                let dt = res.data.data.likedNFT;
                let nftLikeScore = !this.state.favoriteNFT.isLiked ? parseInt(this.state.favoriteNFT.count) + 1 : (parseInt(this.state.favoriteNFT.count) !== 0 ? parseInt(this.state.favoriteNFT.count) - 1 : 0)
                let fN = { isLiked: !this.state.favoriteNFT.isLiked, count: nftLikeScore };
                this.setState({ favoriteNFT: fN });
            }
        }).catch(error => console.log(error));
    }

    // Refresh metadata function
    refreshMetadata = async (_id, collectionID, chainIndex) => {
        this.setState({ nftLoader: true });

        let wData = { nft_id: _id, collection_id: collectionID, chainIndex: chainIndex };
        await apiHelperBlockchain('metadata/refresh-metadata', 'POST', wData).then(res => {
            this.setState({ nftLoader: false });
            if (res.data.status === true) {
                this.setState({ ...this.state, imageUrl: res.data.data.imageUrl, error: false });
                this.props.refreshMetadata(res.data.data._id, res.data.data)
            }
        }).catch(error => console.log(error));
    }

    getDecimalPart = (num) => {
        if (Number.isInteger(num)) {
            return 0;
        }
        const decimalStr = num.toString().split('.')[1];
        return (decimalStr).substring(0, 2);
    }

    makeSeparate = (numb) => {
        let n1 = parseInt(numb);
        let s1 = n1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let s2 = this.getDecimalPart(numb);
        return s1 + "." + s2;
    }

    testPrice = (price) => {
        const precision = (price + ".").split(".")[1].length;
        return parseFloat(price).toFixed(precision);
    }

    onImageError = () => {
        this.setState({ error: true });
    }

    render() {

        // const { currLocale, shareModal } = this.state;
        const { t } = this.props;

        const { fallbacksrc, error } = this.state;
        // const imgURL = (!error) ? this.props.imgURL : fallbacksrc;
        const imgURL = (!error) ? this.state.imageUrl : fallbacksrc;


        let endingFinalTime = "";
        if (this.state.auctionType === 2) {
            endingFinalTime = parseInt((this.state.closingTime) + "000");
        }

        // const all_image = this.props.imgURL;
        // let no_image_available = all_image.filter((image) => image.imgURL == "https://res.cloudinary.com/demo/image/fetch/https://res.cloudinary.com/alchemyapi/image/upload/").shift()

        // console.log('sarvesh Testing',no_image_available)

        return (
            <>
                <MoralisInstance moralisInstance={(instance) => !this.state.callbackInstance && this.setState({ callbackInstance: instance })} />
                <div className={styles.hot__inner_bids}>
                    {/* <div className="d-flex align-items-center justify-content-between">
                        {this.props.is_user === trues ? <></> : */}
                    <>
                        {/* <div className={styles.card__line}>
                                <div className={`d-flex ${styles.card__users}`}>
                                    {this.props.user &&
                                        <Link href={'/nft/profiles/' + (this.props.user.customUrl ? this.props.user.customUrl : this.props.user.walletAddress)}>
                                            <a>
                                                <div className={styles.card__avatar}>
                                                    <Image src={this.props.user.profilePhoto ? s3Link + "profileimage/" + encodeURIComponent(this.props.user.profilePhoto) : avatar} alt="Avatar" width={30} height={30} layout='responsive' />
                                                </div>
                                                {this.props.user.isVerified ?
                                                    <div className={styles.done}>
                                                      <Image src={verified} alt={t('common:all_collections')} width="16" height="16" layout='responsive' />
                                                    </div> : null
                                                }
                                            </a>
                                        </Link>}
                                </div>
                            </div> */}

                    </>
                    {/* }
                    </div>*/}
                    <a href={`/${this.props.router.locale}/nft/tokens/` + (this.props.chainIndex === 2 ? "polygon/" : "ethereum/") + this.props.collection_id + "/" + (this.props.nftId ? this.props.nftId : this.props.signature)}>
                        {/* card images */}
                        <div className={styles.cardImage}>
                            <div className={styles.icon}>
                                <span className={styles.rightIcon}>
                                    <SelectedIcon />
                                </span>
                                <span className={styles.cicleIcon}>
                                    <CircleIcon />
                                </span>
                            </div>
                            <Image src={imgURL ? (imgURL.includes("ipfs://") ? this.getCardImage(imgURL) : imgURL) : cardPreview} onError={this.onImageError} alt="Card preview" width={300} height={315} layout="responsive" loading='lazy' />
                            {/* onError={this.onImageError} */}
                            {/* // in case image not available for display then call this section */}
                            {
                                this.state.nftLoader ?
                                    <div className={styles.no_metadata_available_loading}>
                                        <p>{t('common:loading')} .... </p>
                                        <ShimmerSimpleGallery imageHeight={5} row={1} col={3} gap={10} />
                                    </div>
                                    :
                                    <>
                                        {this.state.error &&
                                            <Link href={'/nft/tokens/' + (this.props.chainIndex === 2 ? "polygon/" : "ethereum/") + this.props.collection_id + "/" + (this.props.nftId ? this.props.nftId : this.props.signature)}>
                                                <div className={styles.no_metadata_available} onClick={this.moreOptDefault} >
                                                    <div className={styles.no_metadata_image} >
                                                        <Image src={Act4} alt="Avatar" width={80} height={80} />
                                                    </div>
                                                    <p className={styles.content_not_availabe}>{t('common:content_not_avl')}</p>
                                                    <p className={styles.refresh_btn} onClick={() => this.refreshMetadata(this.props.nftId, this.props.collection_id, this.props.chainIndex)}>{t('common:refresh_metadata')}</p>
                                                </div>
                                            </Link>
                                        } </>}

                            {this.state.is_multiple ?

                                <div className={styles.multipleNft} onClick={this.moreOptDefault}>
                                    <CopyBidsIocn className={styles.CopyBidIocn} />

                                    {this.props.is_copy_show ?
                                        this.state.copies + " " + t('common:of') + " " + this.state.ownedCopies : this.state.tokenQty
                                    }
                                </div> : null}

                            {this.state.is_user_id ?
                                (this.state.favoriteNFT && this.state.favoriteNFT !== {}) &&
                                <div className={`d-flex align-items-center ${styles.likeNft}`} onClick={this.moreOptDefault}>
                                    <div className={`${this.state.favoriteNFT.isLiked ? styles.active : ""} ${styles.like}`} onClick={() => this.likeNft(this.props.id, this.props.collection_id)}>
                                        <Image src={this.state.favoriteNFT.isLiked ? likeBidh : likeBidd} alt="like" width={22} height={17} layout="responsive" />
                                    </div>
                                    {this.state.favoriteNFT.count ? this.state.favoriteNFT.count : 0}
                                </div>
                                : null}
                        </div>
                        <div className={`text-start d-flex align-items-start justify-content-between ${styles.name} ${styles.opts}`}>
                                <span className={styles.title}>{this.props.title}</span>
                                <div className={styles.moreOpts} onClick={this.moreOptDefault}>
                                    <Dropdown align="end" className={`nav-item ${styles.headerItem}`}>
                                        <Dropdown.Toggle variant="link" aria-label='more' tag="span" className={`nav-link ${styles.navLink}`}>
                                            <MoreOptIcon />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={styles.NftDropdown}>
                                            <Dropdown.Item onClick={this.openIPFSImage}>{t('common:open_IPFS')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                        </div>
                    </a>
                    <div className={styles.cardDetails}>
                        <div className={styles.desc}>
                            {this.props.user &&
                                <>
                                    <Link href={'/nft/profiles/' + (this.props.user.customUrl ? this.props.user.customUrl : this.props.user.walletAddress)}>
                                            <span className={styles.card__avatar}>
                                                <Image src={this.props.user.profilePhoto ? s3Link + "profileimage/" + this.props.user.profilePhoto : avatar} alt="Avatar" width={30} height={30} layout='responsive' />
                                            </span>
                                            <span className={styles.card__title}>
                                                <span className={styles.names}>{this.props.user.displayName ? this.props.user.displayName : (this.props.user.walletAddress).substring(1, 14) + "..."}</span>
                                                {this.props.user && this.props.user.isVerified ?
                                                    <Image src={verified} alt={t('common:   ')} width="16" height="16" />
                                                    : null
                                                }
                                            </span>
                                    </Link>
                                </>
                            }

                        </div>
                        {this.props.put_on_sale === 1 ?
                            <div className={`d-flex align-items-center ${styles.info1}`}>
                                <div className={`text-center w-100 ${styles.card__status}`}>
                                    {t('common:not_for_sale')}
                                </div>
                            </div>
                            :

                            <div className={styles.card__status}>
                                {this.state.auctionType === 1 ?
                                    <>
                                        <span>{t('common:price')}</span>
                                        {/* <div className={styles.priceContent}>
                                                <span className={styles.ethPrice}>
                                                    {this.state.auctionType === 1 ? (this.state.nftPrice < 0.00001) ? "0.00001" : this.testPrice(this.state.nftPrice) : null}
                                                    {this.state.chainIndex === 2 ? " MATIC" : " ETH"}
                                                </span>
                                              <span className={styles.dPrice}>≈ ${this.state.nftUsdPrice ? this.makeSeparate(this.state.nftUsdPrice) : "0"}</span>
                                            </div> */}
                                        {/* black friday page */}
                                        {
                                            // this.state.discountedPrice && parseFloat(this.state.discountedPrice) > 0 ?
                                            //     <div className={`${styles.priceContent} ${styles.discountPrice}`}>
                                            //         <span className={styles.salePrice}>
                                            //             {parseFloat(this.state.discountedPrice.toFixed(6))} {this.state.chainIndex === 2 ? " MATIC" : " ETH"}
                                            //         </span>
                                            //         <span className={styles.ethPrice}>
                                            //             {this.state.auctionType === 1 ? (this.state.nftPrice < 0.00001) ? "0.00001" : this.testPrice(this.state.nftPrice) : null}
                                            //             {this.state.chainIndex === 2 ? " MATIC" : " ETH"}
                                            //         </span>
                                            //         {/* <span className={styles.dPrice}>≈ ${this.state.nftUsdPrice ? this.makeSeparate(this.state.nftUsdPrice) : "0"}</span> */}
                                            //     </div> :
                                            <div className={`${styles.priceContent}`}>
                                                <span className={styles.ethPrice}>
                                                    {this.state.auctionType === 1 ? (this.state.nftPrice < 0.00001) ? "0.00001" : this.testPrice(this.state.nftPrice) : null}
                                                    {this.state.chainIndex === 2 ? " MATIC" : " ETH"}
                                                </span>
                                                {/* <span className={styles.dPrice}>≈ ${this.state.nftUsdPrice ? this.makeSeparate(this.state.nftUsdPrice) : "0"}</span> */}
                                            </div>
                                        }

                                    </>
                                    : null}

                                {this.state.auctionType === 2 ?
                                    <div className='d-flex align-items-center justify-content-between w-100'>
                                        <div className={styles.bid}>
                                            {this.state.auctionType === 2 && !this.state.is_lazy_mint ? this.state.highestBidder === "0x0000000000000000000000000000000000000000" ? <><span className={styles.text}>{t('common:min_bid')}</span><div className={`d-flex align-items-center ${styles.priceInfo}`}><span className={styles.ethPrice}>{(this.state.nftPrice) + " ETH"}</span></div></> : <><span className={styles.text}>{t('common:highest_bid')}</span><div className={`d-flex align-items-center ${styles.priceInfo}`}><span className={styles.ethPrice}>  {(this.state.currentBid) + " ETH"}</span></div></> : null}
                                            {this.state.auctionType === 2 && this.state.is_lazy_mint ? this.state.highestBidder === "0x0000000000000000000000000000000000000000" ? <><span className={styles.text}>{t('common:min_bid')}</span><div className={`d-flex align-items-center ${styles.priceInfo}`}><span className={styles.ethPrice}>{parseFloat(this.state.nftPrice).toFixed(5) + " WETH"}</span></div></> : <><span className={styles.text}>{t('common:highest_bid')}</span><div className={`d-flex align-items-center ${styles.priceInfo}`}><span className={styles.ethPrice}>{(this.state.currentBid) + " WETH"}</span></div></> : null}
                                            {/* <div className={styles.dPrice}>≈ $ {this.state.nftUsdPrice ? parseFloat(this.state.nftUsdPrice).toFixed(5) : "0"}</div> */}
                                        </div>
                                        {this.state.auctionType === 2 ?
                                            endingFinalTime &&
                                            <div className={styles.content}>
                                                <div className={styles.text}>{t('common:time_left')}</div>
                                                <div className='d-flex align-items-center'>
                                                    <TimerIcon />
                                                    <Countdown
                                                        date={endingFinalTime}
                                                        renderer={props => props.completed ? <span>{this.props.t('common:auction_end')}</span> :
                                                            <span>{props.days >= 1 ? props.days + " " + (props.days === 1 ? t('common:day') : t('common:days')) : props.hours + ":" + props.minutes + ":" + props.seconds}  </span>} />
                                                </div>
                                            </div>
                                            : null}
                                    </div> : null}
                            </div>
                        }
                    </div>
                </div>
                <SignInWithWallet
                    signInWithWallet={this.state.signInWithWallet}
                    closeSignInWithWallet={this.closeSignInWithWallet}
                />
                {/* <Modal show={shareModal} onHide={this.shareCloseModal} size="md" centered>
                        <div className="popup__title h4">
                            {t('common:share_NFT')}
                            <div type="button" className="btnClose" onClick={this.shareCloseModal}><svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'><path fillRule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='#23262F' /></svg></div>
                        </div>
                        <Modal.Body className='p-0'>
                            <ul className={`d-flex align-items-center justify-content-between ${styles.shareModal}`}>
                                <Link href="#"><a className={styles.social_link} target="_blank" rel="noopener"><div className={styles.icon}><svg width="20" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.18099 10.9998C0.527274 11.0045 -0.540242 12.8884 0.478555 14.3157C1.93757 16.3596 4.58333 18 9.00035 18C15.8454 18 21.34 12.4217 20.5708 5.8311L21.6977 3.5773C22.4792 2.01428 21.1212 0.23622 19.4076 0.57894L17.9188 0.87669C17.5241 0.66843 17.1156 0.51127 16.7665 0.39741C16.0861 0.17552 15.2626 0 14.5003 0C13.131 0 11.9492 0.35131 11.01 1.05593C10.0815 1.75264 9.55855 2.66825 9.27155 3.50905C9.13925 3.89661 9.05115 4.28534 8.99462 4.65843C8.46335 4.49123 7.92163 4.26626 7.39129 3.99155C6.18809 3.36832 5.21514 2.57822 4.66629 1.88551C3.74546 0.7233 1.79402 0.80947 1.10924 2.32021C0.144105 4.44947 0.408855 6.97346 1.27639 9.0129C1.56717 9.6964 1.94542 10.3721 2.40753 10.9983C2.32842 10.9992 2.25274 10.9996 2.18099 10.9998ZM9.00025 16C5.13691 16 3.13923 14.6007 2.10628 13.1537C2.05987 13.0887 2.10667 13 2.18654 12.9998C3.23738 12.9968 5.39438 12.9471 6.81698 12.1145C6.89055 12.0714 6.87436 11.9629 6.79356 11.9358C3.47739 10.8208 1.59236 6.09859 2.93074 3.1459C2.96143 3.0782 3.05243 3.06927 3.0986 3.12754C4.62747 5.05718 7.97027 6.9472 10.8786 6.99891C10.9417 7 10.9894 6.9427 10.9798 6.88033C10.8626 6.12053 10.4151 2 14.5002 2C15.4757 2 16.9271 2.47583 17.4617 2.9633C17.4864 2.98583 17.5199 2.99606 17.5527 2.9895L19.7997 2.5401C19.8813 2.52378 19.946 2.60845 19.9088 2.68288L18.5151 5.47019C18.5054 5.48954 18.5024 5.51175 18.5061 5.53306C19.4822 11.02 14.9882 16 9.00025 16Z" fill="#777E91"></path></svg></div><span>{t('common:twitter')}</span></a></Link>
                                <Link href="#"><a className={styles.social_link} target="_blank" rel="noopener"><div className={styles.icon}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.99935 16.6665C13.6813 16.6665 16.666 13.6818 16.666 9.99984C16.666 6.31794 13.6813 3.33317 9.99935 3.33317C6.31745 3.33317 3.33268 6.31794 3.33268 9.99984C3.33268 13.6818 6.31745 16.6665 9.99935 16.6665ZM9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39697 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39697 18.3332 9.99935 18.3332Z" fill="#777E91"></path><path d="M9.99935 8.3335C9.99935 7.87326 10.3724 7.50016 10.8327 7.50016H11.666C12.1263 7.50016 12.4993 7.12707 12.4993 6.66683C12.4993 6.2066 12.1263 5.8335 11.666 5.8335H10.8327C9.45193 5.8335 8.33268 6.95279 8.33268 8.3335V10.0002H7.49935C7.03912 10.0002 6.66602 10.3732 6.66602 10.8335C6.66602 11.2937 7.03911 11.6668 7.49935 11.6668H8.33268V16.6668C8.33268 17.1271 8.70577 17.5002 9.16602 17.5002C9.62627 17.5002 9.99935 17.1271 9.99935 16.6668V11.6668H11.666C12.1263 11.6668 12.4993 11.2937 12.4993 10.8335C12.4993 10.3732 12.1263 10.0002 11.666 10.0002H9.99935V8.3335Z" fill="#777E91"></path></svg></div><span>{t('common:facebook')}</span></a></Link>
                                <Link href="#"><a className={styles.social_link} target="_blank" rel="noopener"><div className={styles.icon}><svg width="20" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16.253 0.478239C18.3136 -0.380341 20.3809 1.68688 19.5223 3.74747L13.5988 17.9639C12.4403 20.7442 8.36551 20.2743 7.87034 17.3033L7.13133 12.8692L2.69725 12.1302C-0.273738 11.635 -0.743676 7.56021 2.0366 6.40176L16.253 0.478239ZM17.6761 2.97824C17.8478 2.56612 17.4344 2.15268 17.0223 2.32439L2.80583 8.24791C1.87907 8.63406 2.03572 9.99233 3.02605 10.1574L7.46013 10.8964C8.30308 11.0369 8.96363 11.6974 9.10412 12.5404L9.84313 16.9745C10.0082 17.9648 11.3665 18.1214 11.7526 17.1947L17.6761 2.97824Z" fill="#777E91" /></svg></div><span>{t('common:telegram')}</span></a></Link>
                                <Link href="#"><a className={styles.social_link} target="_blank" rel="noopener"><div className={styles.icon}><svg width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4Z" fill="#777E91" /><path fillRule="evenodd" clipRule="evenodd" d="M5.2318 7.35984C5.58537 6.93556 6.21593 6.87824 6.64021 7.2318L11.3598 11.1648C11.7307 11.4739 12.2694 11.4739 12.6402 11.1648L17.3598 7.2318C17.7841 6.87824 18.4147 6.93556 18.7682 7.35984C19.1218 7.78412 19.0645 8.41468 18.6402 8.76825L13.9206 12.7013C12.808 13.6284 11.192 13.6284 10.0795 12.7013L5.35984 8.76825C4.93556 8.41468 4.87824 7.78412 5.2318 7.35984Z" fill="#777E91" /></svg></div><span>{t('common:email')}</span></a></Link>
                                <Link href="#"><a className={styles.social_link} target="_blank" rel="noopener"><div className={styles.icon}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.961" viewBox="0 0 18 17.961"><path id="link" d="M13.887,1.466a5.4,5.4,0,0,0-1.6.489,6.622,6.622,0,0,0-1.925,1.552c-.916.9-.965.965-.965,1.309a.8.8,0,0,0,.475.745,1,1,0,0,0,.662.007,7.545,7.545,0,0,0,.984-.9C12.592,3.611,12.85,3.428,13.6,3.2a2.293,2.293,0,0,1,.876-.087,2.346,2.346,0,0,1,.89.089,3.329,3.329,0,0,1,2.059,1.716,3.123,3.123,0,0,1,.326,1.145,3.348,3.348,0,0,1-.656,2.3c-.086.114-.807.852-1.6,1.64a11.976,11.976,0,0,1-1.714,1.591,3.321,3.321,0,0,1-4.272-.736.933.933,0,0,0-.675-.421A.828.828,0,0,0,8,10.971c-.12.354-.051.586.306,1.01A4.988,4.988,0,0,0,11.1,13.628a6.037,6.037,0,0,0,1.94,0,5.109,5.109,0,0,0,1.811-.77,13.753,13.753,0,0,0,1.9-1.783c1.08-1.082,1.605-1.632,1.738-1.82A4.942,4.942,0,0,0,15.221,1.49a8.275,8.275,0,0,0-1.334-.025M8.032,7.186A5.014,5.014,0,0,0,5.578,8.307c-.444.371-3.028,3-3.237,3.3a4.945,4.945,0,0,0,3.285,7.767,5.9,5.9,0,0,0,1.8-.065,5.08,5.08,0,0,0,1.712-.741,10.412,10.412,0,0,0,1.21-1.106,14.405,14.405,0,0,0,1-1.056.92.92,0,0,0,0-.723.9.9,0,0,0-.412-.393,1.079,1.079,0,0,0-.642,0,9.163,9.163,0,0,0-.976.9c-1.14,1.128-1.48,1.35-2.324,1.526a3.639,3.639,0,0,1-1.53-.053,3.3,3.3,0,0,1-2.384-3.5A3.423,3.423,0,0,1,3.7,12.535c.089-.118.8-.853,1.583-1.632C6.8,9.394,6.961,9.259,7.477,9.045a3.345,3.345,0,0,1,2.23-.125,3.449,3.449,0,0,1,1.608,1.07.948.948,0,0,0,.682.432.832.832,0,0,0,.832-.536c.12-.354.051-.586-.306-1.01A4.967,4.967,0,0,0,9.765,7.234a6.363,6.363,0,0,0-1.733-.048" transform="translate(-1.416 -1.449)" fill="#777e91"></path></svg></div><span>{t('common:copy')}</span></a></Link>
                            </ul>
                        </Modal.Body>
                    </Modal> */}
                {/* Checkout(Purchase) modal */}
                {/* {(this.state.purchaseModal === true) && this.state.nftDetails ?
                        <BuyModal
                            purchaseModal={this.state.purchaseModal}
                            changeForBuyModal={this.changeForBuyModal}
                            nftDetails={this.state.nftDetails}
                            buyItem={this.state.ownerList && this.state.ownerList[0]}
                            callbackInstance={this.state.callbackInstance}
                        />
                        : null} */}
            </>
        )
    }
}
export default withTranslation()(withRouter(BidsList));